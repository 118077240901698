.notyf .notyf__toast {
  @apply bg-white;
  @apply rounded-lg;
  @apply overflow-hidden;
  @apply pointer-events-auto;
  @apply w-full;
  @apply max-w-sm;
  @apply shadow-lg;
  @apply ring-1;
  @apply ring-black;
  @apply ring-opacity-5;
}

.notyf__wrapper {
  @apply items-start;
}

.notyf__icon>i {
  @apply h-5;
  @apply w-5;
  @apply mt-1;
  display: block;
}

.notyf__icon .INFO {
  @apply h-5;
  @apply w-5;
  @apply bg-black;
  mask-image: url('../../../assets/svg/info-outline.svg');
}

.notyf__icon .WARNING {
  @apply h-5;
  @apply w-5;
  @apply bg-orange-400;
  mask-image: url('../../../assets/svg/exclamation-circle-outline.svg');
}

.notyf__icon .ERROR {
  @apply h-5;
  @apply w-5;
  @apply bg-red-600;
  mask-image: url('../../../assets/svg/exclamation-triangle-outline.svg');
}

.notyf__icon .SUCCESS {
  @apply h-5;
  @apply w-5;
  @apply bg-green-400;
  mask-image: url('../../../assets/svg/check-circle-outline.svg');
}

.notyf__message {
  @apply ml-3;
  @apply w-0;
  @apply flex-1;
  @apply pt-0.5;
  @apply text-sm;
  @apply font-medium;
  @apply text-gray-900;
}


.notyf__dismiss-btn {
  @apply bg-white;
  @apply text-black;
}

.notyf__dismiss-btn:after,
.notyf__dismiss-btn:before {
  @apply text-black;
  @apply bg-black;
}